<template>
  <scroll-layout class="amountStatistics-list">
    <template slot="header">
      <ykc-warm-tip
        type="warning"
        v-if="topTipStatus"
        :description="topTip"
        @click="topTipStatus = false"></ykc-warm-tip>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParam"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'finance:recharge:list:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>
<script>
  import { getLastTime, offlineExport } from '@/utils';
  import { dataStatisticsServe } from '@/service/apis';

  const flowTypes = {
    // 1-微信充值、2-支付宝充值、3-退款、4-充值退回
    1: '微信充值',
    2: '支付宝充值',
    3: '退款',
    4: '充值退回',
  };

  export default {
    name: 'amountStatisticsList',
    data() {
      return {
        topTip:
          '温馨提示：由于统计数据量过大，默认显示近7日统计数据，需要查看其他数据请使用查询功能。',
        tradeNo: null,
        topTipStatus: true,
        searchParam: {
          date: {},
          phone: '',
          userAccount: '',
          flowTimeEnd: '',
          flowTimeStart: '',
          flowType: '',
        },
        tableTitle: '充值明细', // 列表表格
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '流水号', prop: 'flowNumber', fixed: true, minWidth: '220px' },
          { label: '用户账号', prop: 'userAccount', minWidth: '200px' },
          { label: '手机号', prop: 'userPhone', minWidth: '200px' },
          {
            label: '流水类型',
            prop: 'flowType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return <span>{flowTypes[row.flowType] || '——'}</span>;
              },
            },
          },
          {
            label: '金额(元)',
            prop: 'flowAmount',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const style = +row.flowAmount > 0 ? 'color: #70B603' : 'color: #D9001B';
                const text = +row.flowAmount > 0 ? `+${row.flowAmount}` : row.flowAmount;
                return <span style={style}>{text}</span>;
              },
            },
          },
          { label: '余额(元)', prop: 'personalBalance', minWidth: '150px' },
          { label: '操作时间', prop: 'createdTime', minWidth: '160px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      const startDate = getLastTime('yyyy-MM-dd', 7);
      const endDate = getLastTime('yyyy-MM-dd', 1);

      this.searchParam.date = [startDate, endDate];
      this.searchParam.flowTimeStart = startDate;
      this.searchParam.flowTimeEnd = endDate;

      this.requestList();
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            label: '操作时间',
            key: 'date',
            comName: 'YkcDatePicker',
            placeholder: '请选择操作时间',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'phone',
            label: '手机号',
            placeholder: '请输入手机号',
          },
          {
            label: '流水类型',
            comName: 'YkcDropdown',
            key: 'flowType',
            placeholder: '请选择流水类型',
            data: [
              all,
              ...Object.keys(flowTypes).map(key => ({
                id: key,
                name: flowTypes[key],
              })),
            ],
          },
        ];
      },
    },
    methods: {
      /**
       * 请求列表数据
       */
      requestList() {
        const pageInfos = (({ current, size }) => ({ current, size }))(this.pageInfo);
        const reqParams = {
          ...pageInfos,
          phone: this.searchParam.phone,
          flowTimeEnd: this.searchParam.flowTimeEnd,
          flowTimeStart: this.searchParam.flowTimeStart,
          flowType: this.searchParam.flowType,
          userAccount: this.searchParam.userAccount,
        };
        dataStatisticsServe
          .rechargeAndRefundList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'recharge_refund_export',
          jsonNode: {
            ...this.searchParam,
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++ok', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParam, searchData);

        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParam.flowTimeStart, this.searchParam.flowTimeEnd] = searchData.date;
        }
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        console.log('清空查询', form);
        Object.assign(this.searchParam, form);
        // 切换成日，默认状态
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 1);

        this.searchParam.date = [startDate, endDate];
        this.searchParam.flowTimeStart = startDate;
        this.searchParam.flowTimeEnd = endDate;
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .amountStatistics-list {
    .tags {
      width: 160px !important;
      min-width: 0 !important;
      display: flex;
      align-items: center;
      font-size: 12px;
      position: relative;
      top: 35px;
      left: 85px;
      > span {
        display: block;
        width: 16px;
        height: 16px;
        margin-left: 8px;
        font-size: 10px;
        line-height: 16px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        border-radius: 50%;
        background: blue;
      }
    }

    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }
</style>
